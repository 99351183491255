var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[(_vm.loading)?_c('loader',{attrs:{"color":_vm.companyColor}}):_c('v-col',{staticClass:"tw-p-0 tw-flex-col",attrs:{"sm":"12"}},[(!Object.keys(_vm.staffData).length)?_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between"},[_c('icon-and-text',{attrs:{"icon":_vm.addroute,"title":"New Route","desc":"Add a Route","width":_vm.$vuetify.breakpoint.mdAndUp ? '25%': '100%'},on:{"clickAction":_vm.newTerminalTab}}),_c('icon-and-text',{attrs:{"icon":_vm.allroute,"title":_vm.routes.length.toString(),"desc":"All Route","width":_vm.$vuetify.breakpoint.mdAndUp ? '25%': '100%'}}),_c('image-and-text-two',{attrs:{"showImage":false,"title":_vm.mostRecent
            ? _vm.mostRecent.departureTerminalCity +
              ' - ' +
              _vm.mostRecent.destinationTerminalCity
            : 'Nil',"labelOne":"Stop 1 :","labelTwo":"Stop 2 :","value-one":_vm.mostRecent &&
          _vm.mostRecent.routeStops[0] &&
          _vm.mostRecent.routeStops[0].stopPoint
            ? _vm.mostRecent.routeStops[0].stopPoint
            : 'Nil',"value-two":_vm.mostRecent &&
          _vm.mostRecent.routeStops[1] &&
          _vm.mostRecent.routeStops[1].stopPoint
            ? _vm.mostRecent.routeStops[1].stopPoint
            : 'Nil',"width":_vm.$vuetify.breakpoint.mdAndUp ? '48%': '100%',"height":_vm.$vuetify.breakpoint.mdAndUp ? '100px': 'auto'}})],1):_vm._e(),_c('div',{staticClass:"\n        tw-flex tw-w-full tw-flex-col\n        lg:tw-flex-row lg:tw-justify-between lg:tw-py-5\n        tw-mt-20\n        lg:tw-mt-5\n        table-container-div\n        tw-p-5\n        lg:tw-pl-8\n      ",staticStyle:{"background":"#fdfffc"}},[_c('div',{staticClass:"table-container lg:tw-pr-8"},[_c('div',{staticClass:"\n            tw-flex tw-flex-col\n            lg:tw-flex-row lg:tw-justify-between lg:tw-items-center\n          "},[_c('p',{staticClass:"table-header",style:({ color: _vm.companyColor })},[_vm._v(" All Routes ")]),_c('div',{staticClass:"\n              tw-flex tw-flex-row\n              lg:tw-justify-center\n              tw-justify-end tw-items-center\n            "},[_c('div',{staticClass:"tw-mr-2",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Search","solo":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"height":"available","hide-default-footer":_vm.routes.length <= 10,"no-data-text":_vm.not_found,"items":_vm.routes,"checkbox-color":_vm.companyColor,"mobile-breakpoint":0,"search":_vm.search,"item-key":"id","single-select":_vm.singleSelect},scopedSlots:_vm._u([{key:"header.sno",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("S/N")])]},proxy:true},{key:"item.sno",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.sno))])]}},{key:"header.departure",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Departure")])]},proxy:true},{key:"item.departureTerminalCity",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"td-style text-capitalize"},[_vm._v(_vm._s(_vm.getDepartureCity(item)))])]}},{key:"header.destination",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Destination")])]},proxy:true},{key:"item.destinationTerminalCity",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"td-style text-capitalize"},[_vm._v(_vm._s(_vm.getDestinationCity(item)))])]}},{key:"header.routeStop1",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Route-Stop 1")])]},proxy:true},{key:"item.routeStop1",fn:function(ref){
            var item = ref.item;
return [(item.routeStops.length > 0)?_c('span',{staticClass:"td-style text-capitalize"},[_vm._v(_vm._s(_vm.getFirstStop(item.routeStops)))]):_vm._e()]}},{key:"header.routeStop2",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Route-Stop 2")])]},proxy:true},{key:"item.routeStop2",fn:function(ref){
            var item = ref.item;
return [(item.routeStops.length > 0)?_c('span',{staticClass:"td-style text-capitalize"},[_vm._v(_vm._s(_vm.getSecondStop(item.routeStops)))]):_vm._e()]}},(!Object.keys(_vm.staffData).length)?{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer",staticStyle:{"color":"black"},on:{"click":function($event){return _vm.selectEditTab(item.id)}}},[_c('img',{staticClass:"tw-mr-2",attrs:{"src":require("@/assets/icons/edit-icon.svg"),"alt":""}}),_vm._v(" Edit ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showDeleteModal(item.id)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer",staticStyle:{"color":"black"}},[_c('img',{staticClass:"tw-mr-2",attrs:{"src":require("@/assets/icons/del-icon.svg"),"alt":""}}),_vm._v(" Delete ")])],1)],1)],1)]}}:null],null,true),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1)]),_c('delete-modal',{attrs:{"dialog":_vm.dialog,"done-loading":_vm.doneLoading,"description":"Are you sure you want to delete this route"},on:{"close":_vm.closeModal,"deleteAction":_vm.deleteRoute}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }