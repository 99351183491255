<template>
  <v-col sm="12" class="main-body">
    <button
      class="tw-flex tw-items-center tw-mt-6 overview-btn"
      @click="selectTab"
    >
      <img src="@/assets/icons/terminal-arrow-back.svg" alt="" />
      <span class="tw-ml-4">All Routes</span>
    </button>

    <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8">
      <v-col sm="12" class="map-container">
        <div id="map"></div>

        <div class="tw-flex tw-items-center place-icons">
          <button class="tw-bg-white tw-p-2 tw-rounded-lg">
            <img
              src="@/assets/icons/edit-blue.svg"
              alt=""
              @click="selectEditTab"
            />
          </button>
          <button class="tw-bg-white tw-p-2 tw-ml-5 tw-rounded-lg">
            <img src="@/assets/icons/del-blue.svg" alt="" />
          </button>
        </div>
      </v-col>

      <v-simple-table class="tw-mt-8 tw-pl-8">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Departure
              </th>
              <th class="text-left">
                Destination
              </th>
              <th class="text-left">
                Route-Stop 1
              </th>
              <th class="text-left">
                Route-Stop 2
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lagos</td>
              <td>Abuja</td>
              <td>Minna</td>
              <td>Nasarawa</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-row class="tw-mt-3">
      <v-col sm="6" class="tw-px-2 tw-py-2">
        <div class=" tw-w-full tw-bg-white tw-px-3 tw-py-3 tw-rounded-xl">
          <div class="tw-w-full tw-flex tw-justify-end">
            <button class="tw-text-right view-link">View</button>
          </div>
          <div
            class="tw-flex tw-w-full tw-justify-start tw-items-center tw-mt-2"
          >
            <div class="card-div tw-flex tw-items-center">
              <!-- <img src="@/assets/model.jpg" alt="model-x" /> -->
              <div class="img-text-div tw-ml-5">
                <p class="header">EKY - YHJ</p>
                <p class="header-id">Apapa Terminal</p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col sm="6" class="tw-py-2 tw-px-2">
        <div class=" tw-w-full tw-bg-white tw-px-3 tw-py-3 tw-rounded-xl">
          <div class="tw-w-full tw-flex tw-justify-end">
            <button class="tw-text-right view-link">View</button>
          </div>
          <div
            class="tw-flex tw-w-full tw-justify-start tw-items-center tw-mt-2"
          >
            <div class="card-div tw-flex tw-items-center">
              <!-- <img src="@/assets/model.jpg" alt="model-x" /> -->
              <div class="img-text-div tw-ml-5">
                <p class="header">APP - RUS</p>
                <p class="header-id">Yaba Terminal</p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "SingleRoute",
  components: {},
  data() {
    return {
      center: { lat: 6.4994, lng: 3.325 },
      positionOne: { lat: 6.4994, lng: 3.325 },
      positionTwo: { lat: 6.6194, lng: 3.5105 },
    };
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      let dS = new google.maps.DirectionsService();
      // eslint-disable-next-line no-undef
      let dD = new google.maps.DirectionsRenderer();

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 11,
        center: { lat: 6.5236, lng: 3.6006 },
      });

      let request = {
        origin: { lat: 6.5236, lng: 3.6006 },
        destination: this.positionTwo,
        travelMode: "DRIVING",
      };
      dD.setMap(this.map);

      // let newmap = this.map;
      dS.route(request, function(result, status) {
        // console.log(this.map);
        if (status == "OK") {
          let steps = result.routes[0].legs[0].steps;
          // eslint-disable-next-line no-unused-vars
          steps.forEach((res, key) => {
            // console.log(newmap);
            // eslint-disable-next-line no-undef
            // new google.maps.Marker({
            //   position: {
            //     lat: res.start_location.lat(),
            //     lng: res.start_location.lng(),
            //   },
            //   map: newmap,
            //   // label: {
            //   //   text: "",
            //   //   color: "#fff",
            //   // },
            // });
          });
          dD.setDirections(result);
        } else {
          console.log(status);
        }
      });
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    selectTab() {
      // console.log("i'm here");
      this.$store.dispatch("routeComponent/setCurrentComponent", "allRoutes");
      sessionStorage.setItem("routeComponent", "allRoutes");
    },
    selectEditTab() {
      this.$store.dispatch("routeComponent/setCurrentComponent", "editRoute");
      sessionStorage.setItem("routeComponent", "editRoute");
    },
  },
  mounted() {
    this.geolocate();
    this.initMap();
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 300px;
  width: 100%;
  border-radius: 15px;
}
.map-container {
  width: 100%;
  height: 300px;
  position: relative;
}
.place-icons {
  position: absolute;
  top: 50%;
  right: 20px;
}
.map-container .map {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.view-link {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .header-id {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  padding: 0;
  padding-left: 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0;
  padding-left: 5px;
}
</style>
