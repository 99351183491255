/* eslint-disable no-unused-vars */
<template>
    <v-col sm="12">
      <button
          class="tw-flex tw-items-center tw-mt-6 overview-btn"
          @click="backToAllRoute"
          :style="{ color: companyColor }"
      >
        <icons name="arrow-back" :color="companyColor" />
        <span class="tw-ml-4">All Routes</span>
      </button>

      <v-col sm="12" class="tw-bg-white tw-mt-9 tw-rounded-xl tw-w-full tw-py-10">
        <v-col sm="12" class="map-container">
          <div id="map"></div>
        </v-col>
        <loader v-if="loading" :loading="loading" :color="companyColor" />
        <div v-else>
        <div
            class="tw-flex tw-w-full lg:tw-items-center tw-justify-between tw-mb-10 lg:tw-pl-16 lg:tw-pr-40 tw-mt-12 "
         >
          <p class="edit-header" :style="{ color: companyColor }">
            Edit Route
          </p>
          <div @click="closeModal" class="tw-cursor-pointer">
            <icons  name="trash" :color="companyColor" />
          </div>

        </div>

        <div class="lg:tw-pl-16 lg:tw-pr-28">
          <v-row class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle's Route</p>
                <p class="label-info">
                  Choose the Route of the Fleet.
                </p>
              </div>
            </v-col>

            <div class="tw-flex tw-w-full lg:tw-w-8/12">
              <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
                <v-col sm="12" lg="5">
                  <ValidationProvider
                      name="From"
                      rules="required"
                      v-slot="{ classes, errors }"
                  >
                    <div class="" :class="classes">
                      <v-text-field
                          label="From"
                          v-model="routeData.departureTerminalName"
                          solo
                          :disabled="true"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>
                <v-col sm="12" lg="2" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
                  <div class="tw-mb-5">
                    <img src="@/assets/icons/line.svg" alt="" />
                  </div>
                </v-col>
                <v-col sm="12" lg="5">
                  <ValidationProvider
                      name="To"
                      rules="required"
                      v-slot="{ classes, errors }">
                    <div class="" :class="classes">
                      <v-text-field
                          label="To"

                          v-model="routeData.destinationTerminalName"
                          solo
                          :disabled="true"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>
              </div>
            </div>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-w-full tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Route Stops</p>
                <p class="label-info">
                  Location in which the Vehicle Stops.
                </p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <v-combobox
                  :hide-no-data="!search"
                  :items="routeData.routeStops"
                  v-model="model"
                  :search-input.sync="search"
                  hide-details
                  label="Search for an option"
                  multiple
                  small-chips
                  solo
                  :color="companyColor"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <div>
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${companyColor}`"
                        :input-value="selected"
                        outlined
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item.stopPoint }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                    <v-chip
                        v-else
                        v-bind="attrs"
                        :color="`${companyColor}`"
                        :input-value="selected"
                        outlined
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </div>
                </template>
              </v-combobox>
              <small style="font-size: 12px">Press "Enter key" after entering a stop</small>
            </v-col>
          </v-row>

          <div class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10">
            <v-btn @click="backToAllRoute"
                class="first-btn"
                text :style="{color: companyColor, border: '1px solid' + companyColor}">
              Cancel
            </v-btn>
            <v-btn
                text
                @click="updateRoute"
                :style="{ background: companyColor }"
                class="second-btn"
                :loading="doneLoading">
              Done
            </v-btn>
          </div>
        </div>
        </div>
        <delete-modal description="Are you sure you want to delete this route"
         @close="closeModal"
         @deleteAction="deleteRoute" :dialog="showModal" :done-loading="loadingModalBtn"/>
      </v-col>
    </v-col>

</template>

<script>
import Icons from "../../reuseables/Icons";
import {deleteRoute, getRouteById, updateRoute} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
export default {
  name: "EditRoute",
  components: {DeleteModal, Loader, Icons },
  data() {
    return {
      doneLoading: false,
      preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET,
      vehicleTypes: [{ name: "BUS" }, { name: "VAN" }],
      companyColor: "#004AAD",
      id: null,
      image: "",
      terminals: [],
      routes: [],
      vehicleType: "",
      terminalId: "",
      activator: null,
      attach: null,
      colors: [],
      editing: null,
      editingIndex: -1,
      items: [],
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0,
      center: { lat: 6.4994, lng: 3.325 },
      positionOne: { lat: 6.4994, lng: 3.325 },
      positionTwo: { lat: 6.5744, lng: 3.257 },
      map: null,
      companyData : {},
      routeData:{},
      loading : false,
      showModal : false,
      loadingModalBtn : false
    };
  },

  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      let dS = new google.maps.DirectionsService();
      // eslint-disable-next-line no-undef
      let dD = new google.maps.DirectionsRenderer();

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 11,
        center: this.positionOne,
      });

      let request = {
        origin: this.positionOne,
        destination: this.positionTwo,
        travelMode: "DRIVING",
      };
      dD.setMap(this.map);

      // let newmap = this.map;
      dS.route(request, function(result, status) {
        // console.log(this.map);
        if (status == "OK") {
          let steps = result.routes[0].legs[0].steps;
          // eslint-disable-next-line no-unused-vars
          steps.forEach((res, key) => {
          });
          dD.setDirections(result);
        } else {
          console.log(status);
        }
      });
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    backToAllRoute() {
      this.$store.dispatch("routeComponent/setCurrentComponent", "allRoutes");
      sessionStorage.setItem("routeComponent", "allRoutes");
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
   async getRoute(routeId){
      this.loading = true
      let data = {}
     data.transportCompanyId = this.companyData.id
     data.routeId = routeId
    await  getRouteById(data).then(res =>{
        console.log(res.data, "this route details")
        this.positionOne.lat = Number.parseFloat(res.data.departureTerminalLatitude)
        this.positionOne.lng = Number.parseFloat(res.data.departureTerminalLongitude)
        this.positionTwo.lat = Number.parseFloat(res.data.destinationTerminalLatitude)
        this.positionTwo.lng = Number.parseFloat(res.data.destinationTerminalLongitude)
        this.routeData = res.data
        this.model = res.data.routeStops
        this.loading = false
        this.initMap()

      })
          .catch (err => {
            this.doneLoading = false;

            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          })
    },
    updateRoute(){
      this.doneLoading = true
      let  data = {}
      let stops=[]
      this.model.forEach(stopObj =>{
        let stop={}
        if (stopObj.id){
          stop.id = stopObj.id
          stop.stopPoint = stopObj.stopPoint
        }
        else {
          stop.id = null
          stop.stopPoint = stopObj
        }
        stops.push(stop)
      })
      data.transportCompanyId = this.companyData.id
      data.routeId = this.routeData.id
      data.departureTerminalId = this.routeData.departureTerminalId
      data.destinationTerminalId = this.routeData.destinationTerminalId
      data.routeStops = stops
      console.log(data, "data to send")
      updateRoute(data).then(() =>{
        this.backToAllRoute()
        this.doneLoading = false
      })
          .catch (err => {
            this.doneLoading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data,
              success: false,
            });
          })
    },
    closeModal(){
      this.showModal = !this.showModal
    },
    async deleteRoute(){
      this.loadingModalBtn = true
      let data={}
      data.transportCompanyId = this.companyData.id
      data.routeId = this.routeData.id
      await deleteRoute(data).then(res =>{
        console.log(res.data)
        this.loadingModalBtn = false
        this.closeModal()
        this.backToAllRoute()
      })
          .catch (err => {
            this.doneLoading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          })
    }
  },
  mounted() {
    this.geolocate();
    this.initMap();
  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
      this.colors[0] = this.companyColor;
    }
    let routeId = sessionStorage.getItem("editRouteId")
    if (routeId){
      await this.getRoute(routeId)
    }

  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 300px;
  width: 100%;
  border-radius: 15px;
}
.map-container {
  width: 100%;
  height: 300px;
  /* background-color: red; */
  position: relative;
}
.map-container .map {
  width: 100%;
  height: 100%;
  border-radius: 77px;
  .vue-map-container .vue-map {
    border-radius: 20px !important;
  }
}

.hypen-style {
  border: 2px solid rgba(196, 196, 196, 1);
  width: 17px;
}
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
