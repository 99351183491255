var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",style:({ color: _vm.companyColor }),on:{"click":_vm.backToAllRoute}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("All Routes")])],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl tw-py-10 tw-w-full",attrs:{"sm":"12"}},[_c('v-col',{staticClass:"map-container",attrs:{"sm":"12"}},[_c('div',{attrs:{"id":"map"}})]),_c('div',{staticClass:"\n        tw-flex\n        tw-w-full\n        tw-items-center\n        tw-justify-between\n        tw-mb-10\n        lg:tw-pl-16\n        lg:tw-pr-40\n        tw-mt-12\n      "},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v("New Route")])]),_c('div',{staticClass:"lg:tw-pl-16 lg:tw-pr-28"},[_c('v-row',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle's Route")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the Route of the Fleet.")])])]),_c('div',{staticClass:"tw-flex tw-w-full lg:tw-w-8/12"},[_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center "},[_c('v-col',{attrs:{"sm":"12","lg":"5"}},[_c('ValidationProvider',{attrs:{"name":"From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.terminals,"label":"From","append-icon":"mdi-chevron-down","item-text":"terminalName","solo":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.terminalName)+" "+_vm._s(item.address.city)+", "+_vm._s(item.address.state))])]}}],null,true),model:{value:(_vm.fromTerminal),callback:function ($$v) {_vm.fromTerminal=$$v},expression:"fromTerminal"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('v-col',{staticClass:"tw-flex tw-flex-row tw-justify-center tw-items-center",attrs:{"sm":"12","lg":"2"}},[_c('div',{staticClass:"tw-mb-5"},[_c('img',{attrs:{"src":require("@/assets/icons/line.svg"),"alt":""}})])]),_c('v-col',{attrs:{"sm":"12","lg":"5"}},[_c('ValidationProvider',{attrs:{"name":"To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.terminals,"label":"To","append-icon":"mdi-chevron-down","item-text":"terminalName","solo":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.terminalName)+" "+_vm._s(item.address.city)+", "+_vm._s(item.address.state))])]}}],null,true),model:{value:(_vm.toTerminal),callback:function ($$v) {_vm.toTerminal=$$v},expression:"toTerminal"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-w-full tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Route Stops")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Location in which the Vehicle Stops. ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Enter stops for this route","multiple":"","solo":"","color":_vm.companyColor,"hide-details":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"ma-2",attrs:{"color":("" + _vm.companyColor),"input-value":selected,"outlined":"","label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('small',{staticStyle:{"font-size":"12px"}},[_vm._v("Press \"Enter key\" after entering a stop")])],1)],1),_c('div',{staticClass:"\n          tw-flex tw-w-full tw-justify-end tw-items-center\n          action-btn-div\n          tw-mt-10\n        "},[_c('v-btn',{staticClass:"first-btn",style:({ color: _vm.companyColor, border: '1px solid' + _vm.companyColor }),attrs:{"text":""},on:{"click":_vm.backToAllRoute}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","loading":_vm.doneLoading},on:{"click":_vm.handleSaveRoute}},[_vm._v(" Done ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }