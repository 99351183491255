<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div
        class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between"
        v-if="!Object.keys(staffData).length"
      >
        <icon-and-text
          :icon="addroute"
          title="New Route"
          desc="Add a Route"
          :width="$vuetify.breakpoint.mdAndUp ? '25%': '100%'"
          @clickAction="newTerminalTab"
        />
        <icon-and-text
          :icon="allroute"
          :title="routes.length.toString()"
          desc="All Route"
          :width="$vuetify.breakpoint.mdAndUp ? '25%': '100%'"
        />
        <image-and-text-two
          :showImage="false"
          :title="
            mostRecent
              ? mostRecent.departureTerminalCity +
                ' - ' +
                mostRecent.destinationTerminalCity
              : 'Nil'
          "
          labelOne="Stop 1 :"
          labelTwo="Stop 2 :"
          :value-one="
            mostRecent &&
            mostRecent.routeStops[0] &&
            mostRecent.routeStops[0].stopPoint
              ? mostRecent.routeStops[0].stopPoint
              : 'Nil'
          "
          :value-two="
            mostRecent &&
            mostRecent.routeStops[1] &&
            mostRecent.routeStops[1].stopPoint
              ? mostRecent.routeStops[1].stopPoint
              : 'Nil'
          "
          :width="$vuetify.breakpoint.mdAndUp ? '48%': '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px': 'auto'"
        />
      </div>
      <div
        class="
          tw-flex tw-w-full tw-flex-col
          lg:tw-flex-row lg:tw-justify-between lg:tw-py-5
          tw-mt-20
          lg:tw-mt-5
          table-container-div
          tw-p-5
          lg:tw-pl-8
        "
        style="background: #fdfffc"
      >
        <div class="table-container lg:tw-pr-8">
          <div
            class="
              tw-flex tw-flex-col
              lg:tw-flex-row lg:tw-justify-between lg:tw-items-center
            "
          >
            <p class="table-header" :style="{ color: companyColor }">
              All Routes
            </p>
            <div
              class="
                tw-flex tw-flex-row
                lg:tw-justify-center
                tw-justify-end tw-items-center
              "
            >
              <div
                class="tw-mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyColor"> mdi-filter-variant </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            height="available"
            :hide-default-footer="routes.length <= 10"
            :no-data-text="not_found"
            :items="routes"
            :checkbox-color="companyColor"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            class="mt-4 mx-1 table"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.departure>
              <span class="status">Departure</span>
            </template>
            <template v-slot:[`item.departureTerminalCity`]="{ item }">
              <span class="td-style text-capitalize">{{ getDepartureCity(item) }}</span>
            </template>

            <template #header.destination>
              <span class="status">Destination</span>
            </template>
            <template v-slot:[`item.destinationTerminalCity`]="{ item }">
              <span class="td-style text-capitalize">{{ getDestinationCity(item) }}</span>
            </template>

            <template #header.routeStop1>
              <span class="status">Route-Stop 1</span>
            </template>
            <template v-slot:[`item.routeStop1`]="{ item }">
              <span class="td-style text-capitalize" v-if="item.routeStops.length > 0">{{
                getFirstStop(item.routeStops)
              }}</span>
            </template>

            <template #header.routeStop2>
              <span class="status">Route-Stop 2</span>
            </template>
            <template v-slot:[`item.routeStop2`]="{ item }">
              <span class="td-style text-capitalize" v-if="item.routeStops.length > 0">{{
                getSecondStop(item.routeStops)
              }}</span>
            </template>

            <template
              v-slot:[`item.actions`]="{ item }"
              v-if="!Object.keys(staffData).length"
            >
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                      @click="selectEditTab(item.id)"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showDeleteModal(item.id)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                    >
                      <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
      <delete-modal
        :dialog="dialog"
        @close="closeModal"
        :done-loading="doneLoading"
        @deleteAction="deleteRoute"
        description="Are you sure you want to delete this route"
      />
    </v-col>
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import addroute from "@/assets/icons/addroute.png";
import allroute from "@/assets/icons/allroutes.png";
import locationImg from "@/assets/model.jpg";
import {
  deleteRoute,
  getAllTransportCompanyRoute,
} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
// import Icons from "./Icons";

export default {
  name: "AllRouteDashboard",
  components: { DeleteModal, IconAndText, ImageAndTextTwo, Loader },
  data() {
    return {
      addroute,
      allroute,
      locationImg,
      noImage: false,
      companyData: {},
      routes: [],
      companyColor: "#004AAD",
      loading: false,
      headers: [
        { text: "", value: "sno" },
        { text: "Departure", value: "departureTerminalCity" },
        { text: "Destination", value: "destinationTerminalCity" },
        { text: "RouteStop1", value: "routeStop1" },
        { text: "RouteStop2", value: "routeStop2" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Routes will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      mostRecent: {},
      dialog: false,
      doneLoading: false,
      routeId: null,
      staffData: {},
    };
  },

  methods: {
    getFirstStop(stops) {
      if (stops[0] && stops[0].stopPoint) {
        return stops[0].stopPoint;
      } else {
        return "-";
      }
    },
    getSecondStop(stops) {
      if (stops[1] && stops[1].stopPoint) {
        return stops[1].stopPoint;
      } else {
        return "-";
      }
    },
    getDepartureCity(item) {
      return (
          this.toSentenceCase(item.departureTerminalName) +
        " " +
          this.toSentenceCase(item.departureTerminalCity) +
        " - " +
          this.toSentenceCase(item.departureTerminalState)
      );
    },
    getDestinationCity(item) {
      return (
        this.toSentenceCase(item.destinationTerminalName) +
        " " +
          this.toSentenceCase(item.destinationTerminalCity) +
        " - " +
          this.toSentenceCase(item.destinationTerminalState)
      );
    },
    toSentenceCase(text){
          if (text) {
              text = text.toLowerCase()
              return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
          }
    },
    closeModal() {
      this.dialog = !this.dialog;
    },
    showDeleteModal(routeId) {
      this.dialog = !this.dialog;
      this.routeId = routeId;
    },
    async deleteRoute() {
      this.doneLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.routeId = this.routeId;
      await deleteRoute(data)
        .then((res) => {
          console.log(res.data);
          this.doneLoading = false;
          this.closeModal();
          this.getAllRoute();
        })
        .catch((err) => {
          this.doneLoading = false;
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
    selectEditTab(routeId) {
      sessionStorage.setItem("editRouteId", routeId);
      this.$store.dispatch("routeComponent/setCurrentComponent", "editRoute");
      sessionStorage.setItem("routeComponent", "editRoute");
    },
    selectSingle() {
      // console.log("i'm here");
      this.$store.dispatch("routeComponent/setCurrentComponent", "singleRoute");
      sessionStorage.setItem("routeComponent", "singleRoute");
    },
    newTerminalTab() {
      this.$store.dispatch("routeComponent/setCurrentComponent", "addRoute");
      sessionStorage.setItem("routeComponent", "addRoute");
    },
    async getAllRoute() {
      await getAllTransportCompanyRoute(this.companyData.id)
        .then((res) => {
          this.routes = res.data;
          this.mostRecent = this.routes[this.routes.length - 1];
          this.routes.sort(function (a, b) {
            return b.id - a.id;
          });
          this.routes.forEach((route, index) => (route.sno = index + 1));
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
  },

  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let staffData = JSON.parse(sessionStorage.getItem("staffData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    if (staffData) {
      this.staffData = staffData;
    }
    await this.getAllRoute();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.showings {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  .no-image {
    /* background: rgba(124, 176, 246, 1); */
    width: 32px;
    height: 32px;
    border-radius: 50%;

    p {
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin-bottom: 0 !important;
      color: rgba(255, 255, 255, 1);

      /* text-align: left; */
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
