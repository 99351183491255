<template>
  <!-- <router-view ></router-view> -->
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import TerminalDashboard from "../terminal/ViewTerminalDashboard.vue";
import AllRoutes from "./AllRouteDashboard";
import SingleRoute from "./SingleRoute";
import EditRoute from "./EditRoute";
import AddRoute from "./AddRoute";
import { mapGetters } from "vuex";

export default {
  name: "ViewRouteDashboardRoute",
  computed: {
    ...mapGetters("routeComponent", ["currentComponent"]),
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "terminalDashboard") {
        return TerminalDashboard;
      } else if (component === "allRoutes") {
        return AllRoutes;
      } else if (component === "singleRoute") {
        return SingleRoute;
      } else if (component === "editRoute") {
        return EditRoute;
      } else if (component === "addRoute") {
        return AddRoute;
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("routeComponent");

    if (!component) {
      sessionStorage.setItem("routeComponent", "allRoutes");
      this.$store.dispatch("routeComponent/setCurrentComponent", "allRoutes");
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch("routeComponent/setCurrentComponent", component);
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
