var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",style:({ color: _vm.companyColor }),on:{"click":_vm.backToAllRoute}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("All Routes")])],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl tw-w-full tw-py-10",attrs:{"sm":"12"}},[_c('v-col',{staticClass:"map-container",attrs:{"sm":"12"}},[_c('div',{attrs:{"id":"map"}})]),(_vm.loading)?_c('loader',{attrs:{"loading":_vm.loading,"color":_vm.companyColor}}):_c('div',[_c('div',{staticClass:"tw-flex tw-w-full lg:tw-items-center tw-justify-between tw-mb-10 lg:tw-pl-16 lg:tw-pr-40 tw-mt-12 "},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v(" Edit Route ")]),_c('div',{staticClass:"tw-cursor-pointer",on:{"click":_vm.closeModal}},[_c('icons',{attrs:{"name":"trash","color":_vm.companyColor}})],1)]),_c('div',{staticClass:"lg:tw-pl-16 lg:tw-pr-28"},[_c('v-row',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle's Route")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Route of the Fleet. ")])])]),_c('div',{staticClass:"tw-flex tw-w-full lg:tw-w-8/12"},[_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center"},[_c('v-col',{attrs:{"sm":"12","lg":"5"}},[_c('ValidationProvider',{attrs:{"name":"From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"label":"From","solo":"","disabled":true},model:{value:(_vm.routeData.departureTerminalName),callback:function ($$v) {_vm.$set(_vm.routeData, "departureTerminalName", $$v)},expression:"routeData.departureTerminalName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('v-col',{staticClass:"tw-flex tw-flex-row tw-justify-center tw-items-center",attrs:{"sm":"12","lg":"2"}},[_c('div',{staticClass:"tw-mb-5"},[_c('img',{attrs:{"src":require("@/assets/icons/line.svg"),"alt":""}})])]),_c('v-col',{attrs:{"sm":"12","lg":"5"}},[_c('ValidationProvider',{attrs:{"name":"To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"label":"To","solo":"","disabled":true},model:{value:(_vm.routeData.destinationTerminalName),callback:function ($$v) {_vm.$set(_vm.routeData, "destinationTerminalName", $$v)},expression:"routeData.destinationTerminalName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-w-full tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Route Stops")]),_c('p',{staticClass:"label-info"},[_vm._v(" Location in which the Vehicle Stops. ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('v-combobox',{attrs:{"hide-no-data":!_vm.search,"items":_vm.routeData.routeStops,"search-input":_vm.search,"hide-details":"","label":"Search for an option","multiple":"","small-chips":"","solo":"","color":_vm.companyColor},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('div',[(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":("" + _vm.companyColor),"input-value":selected,"outlined":"","label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.stopPoint)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_c('v-chip',_vm._b({attrs:{"color":("" + _vm.companyColor),"input-value":selected,"outlined":"","label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('small',{staticStyle:{"font-size":"12px"}},[_vm._v("Press \"Enter key\" after entering a stop")])],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('v-btn',{staticClass:"first-btn",style:({color: _vm.companyColor, border: '1px solid' + _vm.companyColor}),attrs:{"text":""},on:{"click":_vm.backToAllRoute}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","loading":_vm.doneLoading},on:{"click":_vm.updateRoute}},[_vm._v(" Done ")])],1)],1)]),_c('delete-modal',{attrs:{"description":"Are you sure you want to delete this route","dialog":_vm.showModal,"done-loading":_vm.loadingModalBtn},on:{"close":_vm.closeModal,"deleteAction":_vm.deleteRoute}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }